import React from 'react'
import { FaHeadphones } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import '../../src/App.css';
import '../../src/style.css';
import '../../src/responsive.css';

const CarsDetailsSection = () => {
  return (
    <div className='dad_con'>
    <div className='div_for_pad'>
      <div className='why_cars_title'>
        <h3 className='bef_elem'>Why Cars360?</h3>
      </div>
      <div className='why_us'>
        <div className='wh_chs_us_blk'>
          <div className='whs_u'>
            <img src="./gen-img/Thums-up.jpg" alt="" />
          </div>
        </div>

        <div className='wh_chs_us_blk'>
          <div className='whs_u_inf'>
            <div className='w_car_info'>
              <h3> <FaRegHeart /> Transparent & Secured Deals</h3>
              <p>With hassle-free car selling experience, we are providing our customers transparent and secured dealing. We value strong relationships build on trust and transparency.</p>
            </div>

            <div className='w_car_info'>
              <h3> <FaHeadphones /> Free inspection at your convenience</h3>
              <p>Our free used car inspection is just a call away. And even better, it is available as per your convenience – your time, your place. Immediately after the evaluation you will receive our best offer on your used car.</p>
            </div>

            <div className='w_car_info'>
              <h3> <FaDollarSign /> Accept offer and receive payment</h3>
              <p>As soon as you accept the offer and hand over the car keys to our executive, immediately you receive your payment in your account. And you don’t have to worry about the RC-transfer and paper work, it’s on us.</p>
            </div>

            <div className='w_car_info'>
              <h3> <FaCar /> Car Loan</h3>
              <p>Our executive will take you through the process, get all the required document signed and NOC issued..</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CarsDetailsSection