//Local
// export const API_BASE_URL="http://cars360_api.test/api";
// export const ENVIRONMENT="staging";

//Staging
// export const API_BASE_URL="https://webdemo.apcompinfotech.com/cars360_api/public/api";
// export const ENVIRONMENT="staging";

//Production
export const API_BASE_URL="https://api.cars360.co/api";
export const ENVIRONMENT="production";

//Common
export const GMAPS_KEY="AIzaSyAMUtRe7Z-zU6KFx1qmaMNBxccLecbWzhQ";
export const GA_MEASUREMENT_ID="G-DPV1B1T33G";
export const GA_AD_CONVERSION_TAG="AW-753340143/IdKECKuowawBEO-dnOcC";
export const GA_AW_CODE="AW-753340143";