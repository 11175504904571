import React, { useState } from 'react'
import { NavLink  } from 'react-router-dom'

function Header() {
  return (
    <div>
       <div className='tob-bar-nav' >
        <div className='strt-nav' >
          <img src="./images/cars360-logo.png" alt="" />
        </div>

        <div className='mid-menu-nav' >
          <ul>
            <li className='list-item-inner' > <NavLink exact activeClassName="active" to="/">SELL YOUR CAR</NavLink > </li>
            <li className='list-item-inner' > <NavLink exact activeClassName="active" to="/about">ABOUT US</NavLink > </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Header
