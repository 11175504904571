import '../../src/App.css';
import '../../src/style.css';
import '../../src/responsive.css';
import { FaPhoneVolume } from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Button } from 'react-bootstrap';
import Header from '../component/Header';
import Footer from '../component/Footer';
import TestimonialSection from '../component/TestimonialSection';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCarEstimatedPrice, getFreeInspection } from '../redux/siteAction';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

function Thankyou() {

  const location = useLocation();
  const data = location.state?.data;

  const lead_id = data.lead_id;

  const [estimatePrice, setEstimatePrice] = useState()
  const [modal, setModal] = useState(false)
  useEffect(() => {
    document.title = 'Cars360 | Thank You - Sell Used Car | Best Value Of Used Car | Sell Now';
    window.scrollTo(0, 0);
    estimatePriceData();
  }, [])

  const estimatePriceData = () => {
    getCarEstimatedPrice(data).then(priceData => {
      if (priceData.success) {
        if (priceData.data.min_price == 0 && priceData.data.max_price == 0) {
          setEstimatePrice("Thank you for your enquiry. Our team will reach out to you shortly with your car's best price");
        } else {
          setEstimatePrice("Thank you for your enquiry. Our team will reach out to you shortly with your car's best price");
          // setEstimatePrice(`Your Car's Estimated Price <br/> Rs.  ${priceData.data.min_price}  - Rs.  ${priceData.data.max_price}`);
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [formVisible, setFormVisible] = useState(true);

  const [allFormData, setAllFormData] = useState([]);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const reqInspReport = () => {
    let item = { lead_id, name, email, address }

    getFreeInspection(item).then((res) => {
      if (res.success) {
        setAllFormData([...allFormData, item])

        toast.success('Seller Inspection Report Requested');
        setFormVisible(false);
        setName('')
        setEmail('')
        setAddress('')
      } else {
        toast.error(res.message);
      }
      console.log(res);
    }).catch((err) => {
      toast.error('Something went wrong, please try again later');
      console.log(err);
    });
  }
  // let enquiryData = {
  //   "brand_id": selectedBrands,
  //   "model_id": selectedModels,
  //   "varient_id": selectedVariants,
  //   "year": selectedYear,
  //   "rto_id": selectedRto,
  //   "owner_serial": selectedOwner
  // }

  // const [carEstimatePrice, setEstimatePrice]= useState([])

  // getCarEstimatedPrice(enquiryData).then((res) => {
  //   if(res.success){
  //     if(res.data.length > 0){
  //       setEstimatePrice(res.data)
  //     }
  //   }
  // })

  return (
    <div className='main-con'>
      <Header />
      <div className="container">
        <div className='pricing_page' >
          <div className='car_prc_unlok' >
            <div className='top_head' >
              <div className='top_hd_lft' >
                <h3>{data?.make_yename} - {data?.brand_name} {data?.model_name} {data?.varient_name}</h3>
                <p>{data?.km_driven} - KMs</p>
              </div>
              <div className="top_hd_rgt" >
                <a href="tel:+919910080365"><FaPhoneVolume /> Help</a>
              </div>
            </div>
            <div>
              <div className='imgchk_box' >
                <img src="./images/car360_checkbox.jpg" alt="" />
              </div>
              <div className='car_price_thnky' >
                <h3><div dangerouslySetInnerHTML={{ __html: estimatePrice }} /></h3>
              </div>
            </div>
          </div>

          <div className='car_prc_unlok_t' >
            <Button className='mid_size_secbtn' onClick={() => setModal(true)}>Get Free Inspection at Home</Button>
            <div>
              {formVisible &&
                <Modal
                  size='lg'
                  isOpen={modal}
                  toggle={() => setModal(!modal)}
                >
                  <ModalHeader
                    toggle={() => setModal(!modal)}>
                    Get Free Inspection at Home
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input type="text" name="name" autoComplete='off' value={name} id="name" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input type="email" name="email" autoComplete='off' value={email} id="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="address">Address</Label>
                        <Input type="textarea" name="address" autoComplete='off' value={address} id="address" placeholder="Enter Address" onChange={(e) => setAddress(e.target.value)} />
                      </FormGroup>
                      <Button className="mid_size_secbtn modal_btn" onClick={reqInspReport}>Submit</Button>
                    </Form>
                  </ModalBody>
                </Modal>
              }
            </div>
            <hr />
            <div className=' tt_cr_unlk' >
              <h3>Next Steps</h3>
              <div className="wrapper">
                <ul className="StepProgress">
                  <div className="StepProgress-item is-done"><strong>Get Estimated Price for your Car</strong>
                    With hassle-free car selling experience, we are providing our customers transparent and secured dealing. We value strong relationships build on trust and transparency.
                  </div>
                  <div className="StepProgress-item"><strong>Get a Free Inspection Done at your Doorstep</strong>
                    Our free used car inspection is just a call away. And even better, it is available as per your convenience – your time, your place. Immediately after the evaluation you will receive our best offer on your used car.
                  </div>
                  <div className="StepProgress-item"><strong>Offer Acceptance and Instant Payment</strong></div>
                  As soon as you accept the offer and hand over the car keys to our executive, immediately you receive your payment in your account. And you don’t have to worry about the RC-transfer and paper work, it’s on us.
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TestimonialSection />
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default Thankyou
