import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

function BlogSection() {
  return (
    <div className=' dad_con ' >
    <div className='div_for_pad'>
      <div className='blog-tp-title'>
        <h3 className='bef_elem'>What We Offer</h3>
      </div>

      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        <SwiperSlide>
          <div className='blog-cntn' >
            <div className='blog-top-media' >
              <img src="./blog-img/creta-blog.jpg" alt="" />
            </div>
            <div className='below-inf' >
              <h3>No more photos required</h3>
              <p>Just fill-up a simple form with basic details about your car and your free car inspection is booked. </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='blog-cntn' >
            <div className='blog-top-media' >
              <img src="./blog-img/cruiser-blog.jpg" alt="" />
            </div>
            <div className='below-inf' >
              <h3>No more driving around the dealer addresses</h3>
              <p>Our free used car inspection is just a call away. And even better, it is available as per your convenience. </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='blog-cntn' >
            <div className='blog-top-media' >
              <img src="./blog-img/seatdelhi-blog.jpg" alt="" />
            </div>
            <div className='below-inf' >
              <h3>Accept offer and receive payment</h3>
              <p>As soon as you accept the offer and hand over the car keys, you receive your payment in your account.</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='blog-cntn' >
            <div className='blog-top-media' >
              <img src="./blog-img/creta-blog.jpg" alt="" />
            </div>
            <div className='below-inf' >
              <h3>Free RC transfer</h3>
              <p>As Soon as your car is sold you don’t have to worry about the RC-transfer and paper work, it’s on us.</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
  )
}

export default BlogSection
