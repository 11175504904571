import { createSlice } from '@reduxjs/toolkit';

export const initStateData = {
  'lead_id': null,
  'brand': null,
  'state': null,
  'city': null,
  'model': null,
  'variant': null,
  'fuel': null,
  'reg_no': null,
  'owner': null,
  'year': null,
  'km_driven': null,
  'rto': null,
  'brand_name': null,
  'model_name': null,
  'variant_name': null,
  'rto_name': null,
  'owner_serial_name': null,
  'city_name': null,
  'current_step': 0
};

export const SellCarSlice = createSlice({
  name: 'sellCarSlice',
  initialState: {
    'sellCarData': {
      ...initStateData
    }
  },
  reducers: {
    updateSellCarData: (state, actions) => {
      state.sellCarData = actions.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
    updateSellCarData,
} = SellCarSlice.actions

export default SellCarSlice.reducer