import axios from 'axios';
import { API_BASE_URL } from '../env';

export const getStates = async (countryId) => {
    try {
        let data = await axios.get(API_BASE_URL + '/states?country_id=' + countryId);
        return data.data;
    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const getCities = async (stateId) => {
    try {
        let data = await axios.get(API_BASE_URL + '/cities?state_id=' + stateId);
        return data.data;
    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const getBrands = async () => {
    try {
        let data = await axios.get(API_BASE_URL + '/brand');
        return data.data;

    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const getModels = async (brandId) => {
    try {
        let data = await axios.get(API_BASE_URL + '/car-model?brand_id=' + brandId);
        return data.data;

    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const getVariant = async (modelId, fuelId) => {
    try {
        let data = await axios.get(API_BASE_URL + '/varient?model_id=' + modelId + '&fuel_id=' + fuelId);
        return data.data;

    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const getFuels = async (modelId) => {
    try {
        let data = await axios.get(API_BASE_URL + '/fuel?model_id=' + modelId);
        return data.data;
    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const listRto = async () => {
    try {
        let data = await axios.get(API_BASE_URL + '/rto-list?per_page=100000');
        return data.data;
    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const sendCarEnquiry = async (postData) => {
    try {
        let data = await axios.post(API_BASE_URL + '/create-seller-leads', postData);
        return data.data;
    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const getCarEstimatedPrice = async (filters={}) => {
    try {
        let url = API_BASE_URL + '/get-estimated-price?brand_id='+filters.brand_id+'&model_id='+filters.model_id+'&varient_id='+filters.varient_id+'&year='+filters.make_year+'&state_id='+filters.state_id+'&owner_serial='+filters.owner_serial+'&km_driven='+filters.km_driven
        let data = await axios.get(url)
        return data.data

    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const sendOtpToMobile = async (mobileNum) => {
    try {
        let url = API_BASE_URL + '/registationWithOtp'
        let data = await axios.post(url, {
            "phone": mobileNum,
            "type": "only_verify"
        })
        return data.data

    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const verifyOtpMobile = async (mobileNum, otp, lead_id) => {
    try {
        let url = API_BASE_URL + '/otpVerification'
        let data = await axios.post(url, {
            "phone": mobileNum,
            "otp": otp,
            "lead_id": lead_id
        })
        return data.data

    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}

export const getFreeInspection = async (postData) => {
    try {
        let data = await axios.post(API_BASE_URL + '/seller-inspection-requested', postData);
        return data.data;
    } catch (error) {
        let message = 'Something went wrong, please try again!';
        let status_code = 500;
        if (error.response) {
            message = error.response.data.message;
            status_code = error.response.status;
        }
        return {
            'success': false,
            'message': message,
            'status_code': status_code
        }
    }
}