import React, { useEffect } from 'react'
import { FaCheckCircle } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import Footer from '../component/Footer';
import TestimonialSection from '../component/TestimonialSection';
import Header from '../component/Header';
import '../../src/App.css';
import '../../src/style.css';
import '../../src/responsive.css';
import CarsDetailsSection from '../component/CarsDetailsSection';
import { useLocation } from 'react-router-dom';


const About = () => {

  const { pathname } = useLocation();

  useEffect(()=>{
    document.title = 'Cars360 | About Us - Sell Used Car | Best Value Of Used Car | Sell Now';
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
     <>
     <Header/>
    <div className='main_abt_con'>

      <div className="row abt_row ">
        <div className="col-md-12 d-flex justify-content-center align-items-center">

          <div className='cont_abt'>
            <h3>
              About Us
            </h3>
          </div>
        </div>
      </div>

      <div className="container row_cont">
        <div className="row row_secnd align-items-center ">
          <div className="col-md-6">
            <div className="img_con">
              <img src=" ./about-img/about_car.jpg " alt="" />
            </div>
          </div>

          <div className="col-md-6">
            <h4>ABOUT CARS360</h4>
            <h2 className='abt_hd_stl' >Best solution for selling your car</h2>
            <p>We are an experienced player in the used car trading domain, operationg for over a decade now. We are enabling car owners to easily sell their cars through our secure and hassle-free process.</p>

            <div className="row">
              <div className="col-md-6">
                <div className='abt_pnt_descr' >
                  <p><FaCheckCircle /> We CARS360 are a Trusted Name in <span style={{marginLeft:23}}>Auto Industry.</span></p>
                </div>
                <div className='abt_pnt_descr'>
                  <p><FaCheckCircle /> Immediate offer.</p>
                </div>
                <div className='abt_pnt_descr'>
                  <p><FaCheckCircle /> Hassle-Free Service.</p>
                </div>
                <div className='abt_pnt_descr'>
                  <p><FaCheckCircle /> Secure payment transactions.</p>
                </div>
              </div>
              
              <div className="col-md-6">
                <div className='abt_pnt_descr' >
                  <p><FaCheckCircle /> Visited by Million of Car Buyers and <span style={{marginLeft:23}}>sellers Every Month!</span></p>
                </div>
                <div className='abt_pnt_descr'>
                  <p><FaCheckCircle /> Transparent inspection.</p>
                </div>
                <div className='abt_pnt_descr'>
                  <p><FaCheckCircle /> Hassle free paperwork.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className='help_nmbr_con'>
          <div className="row align-items-center ">
            <div className="col-md-8">
              <h2>
                Call us for further information. Cars360
                team will help you anytime.
              </h2>
            </div>
            <div className="col-md-4">
              <div className='hlp_call' >
                <FaPhoneAlt/>
                <p>CALL US NOW</p>
                {/* <h3>+91 9910080365</h3> */}
                <a href="tel:+919910080365"><h3 style={{ color: '#fff' }}>+91 9910080365</h3></a>
              </div>
            </div>
          </div>
      </div>
    </div>
      <CarsDetailsSection />
      <TestimonialSection />
    <Footer />
    </>
     
  )
}

export default About
